import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col, Card, CardBody, CardTitle, CardImg, CardText, CardFooter, Button, CardSubtitle } from "reactstrap"
import { FaChevronRight } from 'react-icons/fa'
import BackgroundImage from "gatsby-background-image"
import Newsletter from "../components/newsletter"

const NewsArticle = ({title, text, slug, img, date}) => (
  <Card>
    {img && <CardImg tag={Img} fluid={img} alt={title} />}
    <CardBody>
      <CardTitle className="display-6 my-2">
        {title}
      </CardTitle>
      <CardSubtitle className="mt-3 font-weight-light text-muted d-flex align-items-center">
        {date}
      </CardSubtitle>
      <CardText className="pt-3 lead">
        {text}
      </CardText>
    </CardBody>
    <CardFooter className="pt-0 mt-0 bg-transparent justify-content-between align-items-center d-flex border-0">
      <Button 
        tag={Link} 
        to={slug} 
        className="stretched-link pl-0 align-items-center d-flex" 
        color="link">
        <FaChevronRight className="mr-2" /> Read more
      </Button>
    </CardFooter>
  </Card>
)

export default ({data}) => {
  const backgroundFluidImageStack = [
    data.banner.childImageSharp.fluid,
    `linear-gradient(to right, rgba(2, 47, 96, 1), rgba(2, 47, 96, 0.8))`
  ].reverse();

  return (
    <Layout>
      <SEO 
        title="News"
        description="Our latest news, publications, articles and updates about JRC LIVE and supply chain visibility."
      />

      <BackgroundImage
        Tag="section"
        className="d-flex justify-content-center align-items-center vh-60"
        fluid={backgroundFluidImageStack}
        backgroundColor={`#022f60`}
      >
        <Container>
          <Row>
            <Col lg="4">
              <h1 className="display-3 text-white">News.</h1>
              <p className="lead text-white">
              Our latest news, publications, articles and updates about JRC LIVE and supply chain visibility.
              </p>
            </Col>
          </Row>
        </Container>
      </BackgroundImage>

      <section className="py-5 mb-md-5">
        <Container>
          {/*<Row>
            <Col>
              <Nav>
                <NavItem>
                  <NavLink to="#" tag={Link} active>Publications</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#">News</NavLink>
                </NavItem>
              </Nav>              
            </Col>
          </Row>*/}

          <Row className="row-cols-1 row-cols-sm-2 row-cols-xl-4">
          {data.allMarkdownRemark.edges.map((edge, idx) => 
            <Col className="mb-5">    
              <NewsArticle
                title={edge.node.frontmatter.title}
                text={edge.node.excerpt}
                slug={edge.node.fields.slug}
                img={edge.node.frontmatter.cover.childImageSharp.fluid}
                date={edge.node.frontmatter.date}
              />
            </Col>
          )}
          </Row>
        </Container>
      </section>

      <Newsletter />
    </Layout>
  )
}

export const query = graphql`
  query {
    banner: file(relativePath: { eq: "newspaper.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 2560) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            cover {
              childImageSharp {
                fluid(maxWidth: 700, maxHeight: 500, quality: 95) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          excerpt
        }
      }
    }
  }
`